import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";
import {
  fetchReadingsData,
  initializeReadingsStoreData
} from "store/siteConditions/actions";

import feathers from "@feathersjs/client";
import socketio from "@feathersjs/socketio-client";
import { getAuthToken } from "utils/auth.js";

import { useHistory } from "react-router-dom";

const io = require("socket.io-client");
const host = `${process.env.REACT_APP_API_HOST}`;
const app = feathers();
const socket = io(host);

const token = getAuthToken();

app.configure(
  socketio(socket, {
    timeout: 20000
  })
);

const mapDispatchToProps = dispatch => ({
  initializeReadingsStoreData: () => dispatch(initializeReadingsStoreData()),

  getReadingsData: data => dispatch(fetchReadingsData(data)),
});

function DataControllerContainer(props) {

  const history = useHistory();

  const getReadingsrologicData = response => {

    //SOLO ENVIAR LA ACTUALIZACION 
    const site = sessionStorage.getItem('site');
    //console.log('site = '+site);
    //console.log('response = '+JSON.stringify(response));
    if (response.site === parseInt(site)) {
      //console.log('la actualizacion PERTENECE sitio seleccionado');
      props.getReadingsData(response);
    } else {
      //console.log('la actualizacion no pertenece al sitio seleccionado');
    }
  };


  React.useEffect(() => {


    if (token !== null && token !== undefined) {
      socket.on("connect", () => {
        socket.emit(
          "create",
          "authentication",
          {
            strategy: "jwt",
            accessToken: getAuthToken()
          },
          function (error, newAuthResult) {
            //console.log("token socketio OK : ", newAuthResult);

            if (error !== null && error !== undefined) {
              //console.log("token socketio 2 : ", error);
              sessionStorage.removeItem("loggedin")
              history.push('/login');
            }
          });
      });
    }

    props.initializeReadingsStoreData();


    app
      .service("readings")
      .on("updateDataReadingsSensors", response => getReadingsrologicData(response));


    return () => {
      app.service("readings").removeListener("updateDataReadingsSensors");

    };
  }, []);

  return null;
}

DataControllerContainer.propTypes = {
  getData: PropTypes.func
};

export default connect(
  null,
  mapDispatchToProps
)(DataControllerContainer);
