/*eslint-disable*/
import React, { Component } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import Switch from '@material-ui/core/Switch';
// nodejs library that concatenates classes
import styles from "assets/jss/components/configMenuStyle.js";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import Drawer from "@material-ui/core/Drawer";
import classNames from "classnames";


const useStyles = makeStyles(styles);

export default function ConfigMenu(props) {
  const classes = useStyles();

  const IOSSwitch = withStyles((theme) => ({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
      overflow: 'inherit',
    },
    switchBase: {
      padding: 1,
      color: '#DA851B',
      opacity: 1,
      border: 'none',
      '&$checked': {
        '& + $track': {
          backgroundColor: '#1196C7',
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: '#2B2B62',
        border: '6px',
      },
    },
    thumb: {
      width: 24,
      height: 24,
      color: '#DA851B'
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid #ffffff`,
      backgroundColor: '#2B2B62',
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  }))(({ classes, ...props }) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    );
  });

  const theme = (
    <>
      <div className={classes.subtitleHeader}>
        <label className={classes.subtitle}>Modo oscuro</label>
      </div>
      
      <Grid container 
        direction="row"
        justify="center"
        alignItems="center">
        <Grid item xs={6}>
            <label className={classes.subtitle}>Off</label>
              <IOSSwitch checked={props.theme === "dark" ? true : false} onChange={props.handleSwitchTheme} name="checkedC" />
            <label className={classes.subtitle}>On</label>
        </Grid>
      </Grid>
      </>
  );

  return (
    <div>
      <Drawer
        variant="temporary"
        anchor={"right"}
        open={props.open}
        classes={{
          paper: classNames(classes.drawerPaper, {
            [classes.drawerPaperRTL]: props.rtlActive
          })
        }}
        onClose={props.handleDrawerToggle}
        ModalProps={{
          keepMounted: true // Better open performance on mobile.
        }}
      >
      <div className={classes.titleHeader}>
        <label className={classes.title}>Par&aacute;metros generales</label>
      </div>
        <div className={classes.sidebarWrapper}>{theme}</div>
        <div
          className={classes.background}
          style={{ backgroundColor: "grey" }}
        />
      </Drawer>
    </div>
  );
}

ConfigMenu.propTypes = {
  bgImage: PropTypes.string,
  handleFixedClick: PropTypes.func,
  rtlActive: PropTypes.bool,
  fixedClasses: PropTypes.string,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  handleColorClick: PropTypes.func,
  handleImageClick: PropTypes.func
};
