/* eslint-disable react/prop-types */
import React from "react";
// import "./App.css";
import { AppRoutes } from "./routes";
// import { ThemeProvider } from "@material-ui/core/styles";
// import MainTheme from "./themes/main";
import { connect } from "react-redux";

import ModalRootContainer from "containers/common/modalRoot/modalRoot";

import { Router } from "react-router-dom";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { createBrowserHistory } from "history";
import Layout from "components/layouts/layout/layout";
import SettingsManagerContainer from "containers/common/settingsManager/settingsManager";
import DataControllerContainer from "containers/common/dataController/dataController";

import backgroudImage from "assets/img/sateldarkbg.png";
import lightBackgroundImage from "assets/img/sateldatabg.png";
import { getAuthToken } from "utils/auth.js";


// eslint-disable-next-line no-unused-vars
function mapStateToProps(state) {
  const { settings } = state;

  return {
    theme: settings.theme
  };
}

const hist = createBrowserHistory();

// eslint-disable-next-line no-unused-vars
const unlisten = hist.listen((location, action) => {});

///////////////////////////////////////////////////////
//              CORPORATE   COLORS                   //
///////////////////////////////////////////////////////
/*
  Logo Dark Blue:   #2B2B62
  Logo Orange:      #DA851B
  Background Blue:  #1196C7
  Dark Gray:        #555555
  Light Gray:       #AAAAAA
  Pure white:       #FFFFFF

  Note that Satelnet tends to not use pure black (#000000)
  Try Dark Gray instead when applicable

  Transparency Values:
  Append these extra two digits at the end of a hex code to
  change transparency. *MOST* places support it

  Commonly used values in the style sheet:
  33%: 55
  50%: 80
  66%: AA
  (imho feel free to deviate if it leads to a better look
  and *especially* accessibility)
*/
const darkTheme = createMuiTheme({
  backgroundImg: `url(${backgroudImage})`,
  backgroundSize: "cover",
  background: "#2B2B62EE",
  // Change the last two digits in loginbackground to adjust opacity of the login sidebar
  loginbackground: "#1196C700",
  color: "white",
  chartTextColor: "white",
  borderColor: "#DA851B",
  titleColor: "#DA851B",
  axisBarColor: "#AAFFAA",
  axisTextBarColor: "#AAFFAA",
  markerColor: "#AAFFAA",
  tACLineColor: "#5cfff5",
  tACBackLineColor: "white",
  generatorLegendColor: "white",
  svgColor: "#FFF",
  svgFilledTextColor: "black",
});

const lightTheme = createMuiTheme({
  backgroundImg: `url(${lightBackgroundImage})`,
  background: "linear-gradient(to right top, #00578BEE, #1196C7EE, #00578BEE)",
  // Change the last two digits in loginbackground to adjust opacity of the login sidebar
  loginbackground: "#2B2B6200",
  color: "white",
  chartTextColor: "white",
  borderColor: "#DA851B",
  titleColor: "#DA851B",
  axisBarColor: "#050",
  axisTextBarColor: "#050",
  markerColor: "#050",
  tACLineColor: "#5c96ff",
  tACBackLineColor: "black",
  generatorLegendColor: "black",
  svgColor: "black",
  svgFilledTextColor: "#FFF"
});


// eslint-disable-next-line no-unused-vars
function App(props) {
  React.useEffect(() => {
    return () => {
      unlisten();
    };
  }, []);

  var token = getAuthToken();

  return (
    <div className="App">
      <React.Fragment>
        <Router history={hist}>
          {token !== null && token !== undefined ? (
            <DataControllerContainer/>
          ) : null}

          <SettingsManagerContainer />
          <MuiThemeProvider
            theme={props.theme === "light" ? lightTheme : darkTheme}
          >
           {/* {!props.showMe ? <LoginBlumar /> : <Layout>{AppRoutes}</Layout>} */}
           <Layout>{AppRoutes}</Layout>

          </MuiThemeProvider>
        </Router>
        <ModalRootContainer />
      </React.Fragment>
    </div>
  );
}

export default connect(mapStateToProps)(App);
