import axios from "axios";
import siteConditions from "./siteConditions";
import iotMap from "./iotMap";
import auth from "./auth";

import { getAuthToken, setAuthToken } from "../utils/auth.js";

export const API_URL = `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}`;
export const WEB_URL = `${process.env.REACT_APP_WEB_HOST}${process.env.REACT_APP_API_PATH}`;

// ----------------- //
// Instancia anónima //
// ----------------- //


export const axiosAnonInstance = axios.create({
  baseURL: API_URL
});

axiosAnonInstance.interceptors.response.use(response => {
  return response;
});

axiosAnonInstance.interceptors.request.use(async config => {
  return config;
});

// ----------------- //
// Instancia con token //
// ----------------- //

export const axiosInstance = axios.create({
  baseURL: API_URL
});

axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  err => {
    return new Promise((resolve, reject) => {
      const originalReq = err.config;
      if (
        err.response.status === 401 &&
        err.config &&
        !err.config.__isRetryRequest
      ) {
        originalReq._retry = true;

        let res = auth.login().then(res => {
          setAuthToken(res.data.accessToken);
          originalReq.headers["Authorization"] =
            "bearer " + res.data.accessToken;
          return axios(originalReq);
        });
        resolve(res);
      }
      reject(err);
    });
  }
);

axiosInstance.interceptors.request.use(async config => {
  const token = getAuthToken();
  config.headers["Authorization"] = `Bearer ${token}`;
  return config;
});

export default {
  siteConditions,
  iotMap,
  auth
};