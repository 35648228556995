/* eslint-disable no-unused-vars */
import { get } from "lodash-es";
import mapSiteConditions from "selectors/mapSiteConditions";
import { createActions } from "redux-actions";

import {
  getSiteData,
  setSiteData
} from "utils/localStorageHelper";

var moment = require("moment");
require("moment/locale/es");

const updateTime = 600000;
const retryTime = 10000;

// ------------------- //
// ACTIONS SINCRÓNICOS //
// ------------------- //

const actionOptions = {
  prefix: "SITE",
  namespace: "-"
};

export const {
  fetchDataStart,
  fetchDataSuccess,
  fetchDataFail,
  initializeStoreDataStart,
  initializeStoreDataSuccess,
  initializeStoreDataFail,
  setNextUpdate,
  updateUnitDataStore
} = createActions(
  {
    FETCH_DATA_START: undefined, 
    FETCH_DATA_SUCCESS: data => ({ data }),
    FETCH_DATA_FAIL: error => ({ error }),
    INITIALIZE_STORE_DATA_START: undefined,
    INITIALIZE_STORE_DATA_SUCCESS: date => ({ date }),
    INITIALIZE_STORE_DATA_FAIL: error => ({ error }),
    SET_NEXT_UPDATE: date => ({ date }),
    UPDATE_UNIT_DATA: date => ({ date })
  },
  actionOptions
);

export function fetchReadingsData(response) {
  return async (dispatch, getState, api) => {
    dispatch(fetchDataStart());
    let storedData = getSiteData();
    let data = response.em;
    data = updateStoreData(storedData, data);

    console.log('fetchReadingsData --- '+JSON.stringify(data)); 

    try {
      setSiteData(data);
      dispatch(fetchDataSuccess(mapSiteConditions(data)));
      dispatch(
        initializeStoreDataSuccess(
          moment(response.horamuestra, "x").format("YYYY-MM-DD H:mm")
        )
      );
    } catch (error) {
      console.log("Error:", error);
      const message = get(
        error,
        "response.data.error_description",
        "Ocurrió un error al recuperar los datos de los sensores."
      );
      dispatch(fetchDataFail(message));
    }
  };
}

export function initializeReadingsStoreData() {
  return async (dispatch, getState, api) => {
    dispatch(initializeStoreDataStart());

    try {
      let storedData = getSiteData();
      if (storedData == null || isNaN(storedData.tempMin)) {
        const response = await api.siteConditions.getData();
        console.log('initializeReadingsStoreData --- '+JSON.stringify(response)); 
        let data = response.data[0].em;

        console.log('initializeReadingsStoreData --- '+JSON.stringify(data)); 


        setSiteData(data);
        dispatch(fetchDataSuccess(mapSiteConditions(data)));
        dispatch(
          initializeStoreDataSuccess(
            moment(response.data[0].horamuestra).format("YYYY-MM-DD H:mm")
          )
        );
      } else {
        dispatch(fetchDataSuccess(mapSiteConditions(storedData)));
        dispatch(
          initializeStoreDataSuccess(moment().format("YYYY-MM-DD H:mm"))
        );
      }
    } catch (error) {
      console.log("Error:", error);
      const message = get(
        error,
        "response.data.error_description",
        "Ocurrió un error al recuperar los datos de los sensores."
      );
      dispatch(initializeStoreDataFail(message));
    }
  };
}

export function updateUnitData() {
  return async dispatch => {
    dispatch(fetchDataStart());

    try {
      const storedData = getSiteData();
      dispatch(fetchDataSuccess(mapSiteConditions(storedData)));
      dispatch(initializeStoreDataSuccess(moment().format("YYYY-MM-DD H:mm")));
    } catch (error) {
      console.log("Error:", error);
      const message = get(
        error,
        "response.data.error_description",
        "Ocurrió un error al recuperar los datos de los sensores."
      );
      dispatch(fetchDataFail(message));
    }
  };
}

function updateTimerMilliseconds(dispatch, milliseconds) {
  let momentDate = moment();
  momentDate.add(milliseconds, "milliseconds");
  dispatch(setNextUpdate(momentDate.toDate()));
}

const updateStoreData = (actualData, newData) => {
  return {
    ...actualData,
    ...newData
  };
};
