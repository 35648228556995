import { createSelector } from "reselect";
import { round } from "utils/math";

const mapSiteConditions = createSelector(
  data => data,
  data =>
    typeof data !== "undefined" && data !== null
      ? {
          
          temperature: {
            current: round(data.temperature, -1),
            min: round(data.min && data.min.temperature, -1),
            max: round(data.max && data.max.temperature, -1)
          },
          acidity: {
            current: getAcidity(data.acidity, 0),
            min: round(data.min && data.min.acidity, -1),
            max: round(data.max && data.max.acidity, -1)
          },
          conductivity: {
            current: round(data.conductivity, 0),
            min: round(data.min && data.min.conductivity, 0),
            max: round(data.max && data.max.conductivity, 0)
          },
          phreaticLv: {
            current: round(data.phreatic, 0),
            min: round(data.min && data.min.phreatic, 0),
            max: round(data.max && data.max.phreatic, 0)
          }
        }
      : {
          temperature: {
            current: 0,
            min: 0,
            max: 0
          },
          acidity: {
            current: 0,
            min: 0,
            max: 0
          },
          conductivity: {
            current: 0,
            min: 0,
            max: 0
          },
          phreaticLv: {
            current: 0,
            min: 0,
            max: 0
          }
        }
);

export default mapSiteConditions;

const getAcidity = (temperature, windspeed) => {
  return round((
    13.12 +
      0.6215 * temperature -
      11.37 * Math.pow(windspeed, 0.16) +
      0.3965 * temperature * Math.pow(windspeed, 0.16))
  , -1);
};