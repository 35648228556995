/* eslint-disable react/prop-types */
/* eslint-disable react/no-children-prop */
import React from "react"
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import classNames from "classnames";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Collapse from "@material-ui/core/Collapse"; 
import ListItem from "@material-ui/core/ListItem";
import IconExpandLess from "@material-ui/icons/ExpandLess";
import IconExpandMore from "@material-ui/icons/ExpandMore";
import { NavLink, useLocation } from "react-router-dom";
import styles from "assets/jss/components/menuItemStyle.js";
import Icon from "@material-ui/core/Icon";

const useStyles = makeStyles(styles);

const NavMenuItem = props => {
  const classes = useStyles();

  const { onClick, path, children } = props; 

  let location = useLocation();

  function activeRoute(routeName) {
    return location.pathname.indexOf(routeName) > -1 ? true : false;
  }
  var activePro = " ";

  var listItemClasses;
  listItemClasses = classNames({
    [" " + classes["blue"]]: activeRoute(path)
  });

  if (!path || typeof path !== "string") {
    return (
      <ListItem
        button
        className={classes.itemLink + listItemClasses}
        children={children}
        onClick={onClick}
      />
    );
  }

  return (
    <NavLink
      to={path}
      className={activePro + classes.item}
      activeClassName="active"
    >
      <ListItem
        button
        className={classes.itemLink + listItemClasses}
        children={children}
        to={path}
      />
    </NavLink>
  );
};

const MenuItem = props => {
  const {
    name,
    icon,
    hasSubmenu,
    subMenuList = [],
    path,
    rtlName,
    rtlActive,
    submenu
  } = props;
  
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  let location = useLocation();

  function activeRoute(routeName) {
    return location.pathname.indexOf(routeName) > -1 ? true : false;
  }

  const whiteFontClasses = classNames({
    [" " + classes.whiteFont]: activeRoute(path)
  });

  function handleClick() {
    setOpen(!open);
  }

  const MenuItemRoot = (
    <NavMenuItem path={path} onClick={handleClick}>
      {/* Display an icon if any */}
      {icon ? (
        typeof icon === "string" ? (
          <Icon
            className={classNames(classes.itemIcon, whiteFontClasses, {
              [classes.itemIconRTL]: rtlActive
            })}
          >
            {icon}
          </Icon>
        ) : (
          <props.icon
            className={classNames(classes.itemIcon, whiteFontClasses, {
              [classes.itemIconRTL]: rtlActive
            })}
          />
        )
      ) : null}
      {hasSubmenu && !open && (
        <IconExpandMore
          className={classNames(classes.itemIconExpand, whiteFontClasses, {
            [classes.itemIconExpandRTL]: rtlActive
          })}
        />
      )}
      {hasSubmenu && open && (
        <IconExpandLess
          className={classNames(classes.itemIconExpand, whiteFontClasses, {
            [classes.itemIconExpandRTL]: rtlActive
          })}
        />
      )}
      <ListItemText
        primary={rtlActive ? rtlName : name}
        className={classNames(classes.itemText, whiteFontClasses, {
          [classes.itemTextRTL]: rtlActive,
          [classes.subMenuText]: submenu
        })}
        disableTypography={true}
      />
    </NavMenuItem>
  );

  const MenuItemChildren = hasSubmenu ? (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <Divider />
      <List component="div" className={classes.list}>
        {subMenuList.map((submenu, index) => (
          <MenuItem {...submenu} key={index} submenu={true} />
        ))}
      </List>
    </Collapse>
  ) : null;

  return (
    <>
      {MenuItemRoot}
      {MenuItemChildren}
    </>
  );
};

export default MenuItem;
