import { axiosAnonInstance } from "./api.js";

export default {
  LoginAuthentication: (email,password) =>
    axiosAnonInstance.post(process.env.REACT_APP_API_AUTHENTICATION, {
      email: email,// email,//process.env.REACT_APP_API_USER,
      password: password,//password//process.env.REACT_APP_API_PASS
			strategy: "local"//process.env.REACT_APP_API_STRATEGY
    })
};
