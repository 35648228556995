import { combineReducers } from "redux";
import modal from "./modal/reducer";
import siteConditions from "store/siteConditions/reducer";
import settings from "store/settings/reducer";
import login from "store/login/reducer";

export default combineReducers({
  modal,
  siteConditions,
  settings,
  login
});
