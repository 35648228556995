// @material-ui/icons
import React from "react";
import { RoomOutlined, LocalDrink, Assessment, ExitToApp } from "@material-ui/icons";
import { ReactComponent as LogoSMA } from "./assets/img/sma.svg";

const SiteConditionsContainer = React.lazy(() =>
  import("containers/pages/siteConditions/siteConditions")
);

const IotMapContainer = React.lazy(() =>
  import("containers/pages/iotMap/iotMap")
);

const ReportsContainer = React.lazy(() =>
  import("containers/pages/reports/reports")
);

const DataContainer = React.lazy(() =>
  import("containers/pages/data/data")
);

const Logout = React.lazy(() =>
  import("containers/pages/logout/Logout")
);

const routes = [
  {
    path: "/iotMap",
    name: "Mapa General",
    icon: RoomOutlined,
    component: IotMapContainer,
    loggedIn: false,
    hasSubmenu: false,
		hideFromMenu: true
  },
  {
    path: "/site",
    name: "Condiciones de los Pozos",
    icon: LocalDrink,
    component: SiteConditionsContainer,
    loggedIn: false,
    hasSubmenu: false,
		hideFromMenu: true
  },
	{
    path: "/reports",
    name: "Reportes SMA",
    icon: LogoSMA,
    component: ReportsContainer,
    loggedIn: false,
    hasSubmenu: false,
  },
	{
    path: "/data",
    name: "Datos Generales",
    icon: Assessment,
    component: DataContainer,
    loggedIn: false,
    hasSubmenu: false,
  },
  {
    path: "/login",
    name: "Logout",
    icon: ExitToApp,
    component: Logout,
    loggedIn: false,
    hasSubmenu: false
    // hideFromMenu: true
  }

];  

export default routes;