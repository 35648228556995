// Settings

export const getLoggedIn = () => {
  return sessionStorage.getItem("loggedin");
};
export const setLoggedIn = async state => {
  await sessionStorage.setItem("loggedin", state);
};


export const getWindUnit = () => {
  //console.log('getWindUnit => '+sessionStorage.getItem("windUnit"));
  return sessionStorage.getItem("windUnit");
};

export const setWindUnit = async unit => {
  await sessionStorage.setItem("windUnit", unit);
};

export const getCurrentsUnit = () => {
  return sessionStorage.getItem("currentsUnit");
};

export const setCurrentsUnit = async unit => {
  await sessionStorage.setItem("currentsUnit", unit);
};

export const getTheme = () => {
  return sessionStorage.getItem("theme");
};

export const setTheme = async theme => {
  await sessionStorage.setItem("theme", theme);
};

// Fin Settings

export const setWaterConditionsData = async data => {
  await sessionStorage.setItem("waterConditions", JSON.stringify(data));
  await sessionStorage.setItem(
    "waterConditionsLastUpdate",
    new Date().toISOString()
  );
};

export const getWaterConditionsData = () => {
  try {
    let data = sessionStorage.getItem("waterConditions");
    if (data == null) return null;
    return JSON.parse(data);
  } catch {
    return null;
  }
};

export const setTidesAndCurrentsData = async unit => {
  await sessionStorage.setItem("tidesAndCurrents", JSON.stringify(unit));
  await sessionStorage.setItem(
    "tidesAndCurrentsLastUpdate",
    new Date().toISOString()
  );
};

export const getTidesAndCurrentsData = () => {
  try {
    let data = sessionStorage.getItem("tidesAndCurrents");
    if (data == null) return null;
    return JSON.parse(data);
  } catch {
    return null;
  }
};

// ----------------------------------//
// --- SiteData -----------//
// ----------------------------------//

export const setSiteData = async data => {
  await sessionStorage.setItem("siteData", JSON.stringify(data));
  await sessionStorage.setItem(
    "siteDataLastUpdate",
    new Date().toISOString() 
  );
};

export const getSiteData = () => {
  try {
    let data = sessionStorage.getItem("siteData");
    if (data == null) return null;
    return JSON.parse(data);
  } catch {
    return null;
  }
};

export const getReportsData = () => {
  try {
    let data = sessionStorage.getItem("reportsData");
    if (data == null) return null;
    return JSON.parse(data);
  } catch {
    return null;
  }
};

export const setReportsData = async data => {
  await sessionStorage.setItem("reportsData", JSON.stringify(data));
  await sessionStorage.setItem(
    "reportsDataLastUpdate",
    new Date().toISOString() 
  );
};

// ----------------------------------//
// --- GeneratorsData -----------//
// ----------------------------------//

export const setGeneratorData = async (data, generatorNumber) => {
  await sessionStorage.setItem(
    "generator" + generatorNumber + "Data",
    JSON.stringify(data)
  );
};

export const getGeneratorData = generatorNumber => {
  try {
    let data = sessionStorage.getItem("generator" + generatorNumber + "Data");
    if (data == null) return null;
    return JSON.parse(data);
  } catch {
    return null;
  }
};
