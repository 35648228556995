import React from "react";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import SidebarContainer from "containers/common/sidebar/sidebar.js";
import Menu from "@material-ui/icons/Menu";
import SettingsIcon from "@material-ui/icons/Settings";
import IconButton from "@material-ui/core/IconButton";
import routes from "routeList.js";
import styles from "assets/jss/layouts/layoutStyle.js";
import logo from "assets/img/logo.png";
import ConfigMenuContainer from "containers/common/configMenu/configMenu";
import { connect } from "react-redux";
import {
  updateLoginState
} from "store/login/actions";

import {
  getLoggedIn
} from "utils/localStorageHelper";

let ps;

const useStyles = makeStyles(styles);

const mapStateToProps = state => ({
  loggedIn: state.login.loggedin,
});

const mapDispatchToProps = dispatch => ({
  updateLogedIn: data => dispatch(updateLoginState(data))
});

const Layout = ({ ...rest }) => {

// export default function Layout({ ...rest }) {
  // styles
  const theme = useTheme();
  const classes = useStyles(theme);
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [mobileConfigOpen, setMobileConfigOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleConfigToggle = () => {
    setMobileConfigOpen(!mobileConfigOpen);
  };

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
      setMobileConfigOpen(false);
    }
  };

  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);

  React.useEffect(() => {
    rest.updateLogedIn(getLoggedIn());
  }, []);



  //console.log('props => '+rest.loggedIn);

  return (
    <div className={classes.wrapper}>
      <SidebarContainer
        routes={routes}
        logo={logo}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        {...rest}
      />
      <ConfigMenuContainer
        handleDrawerToggle={handleConfigToggle}
        open={mobileConfigOpen}
        {...rest}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <div className={classes.content}>
          <div className={classes.container}>{rest.children}</div>
        </div>
        {rest.loggedIn ? ( 
          <React.Fragment>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
              className={classes.fixedMenu}
            >
              <Menu />
            </IconButton>

            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleConfigToggle}
              className={classes.fixedConfigMenu}
            >
              <SettingsIcon />

            </IconButton>
          </React.Fragment>
        ) :  null}
      </div>
    </div>
  );
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Layout);