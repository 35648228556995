import { axiosInstance } from "./api";

export default {
  getData: () =>
    axiosInstance.get(//"/datasensor"
		),
  getPluviosityData: () =>
    axiosInstance.get(
      //"/datasensors?$sort[dataTimeSample]=-1&$limit=1&gwToken=" + sessionStorage.getItem('gwToken') + "&site=" + sessionStorage.getItem('site')
    )
};
