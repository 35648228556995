import { axiosInstance } from "./api";
//http://localhost:3031/sitios?companyId=5&$limit=20
export default {
    getPlacesByCompany: (startdate) =>
        // axiosInstance.get("/sitios?companyId=" + sessionStorage.getItem('company') + "&$limit=40"),
        axiosInstance.get("/sites?$sort[companyId]=-1&$limit=40&companyId=" + sessionStorage.getItem('company')),

    getPluviosityData: () =>
        axiosInstance.get(
            "/sites?$sort[horamuestra]=-1&$limit=1&company=" + sessionStorage.getItem('company') + "e=" + sessionStorage.getItem('site')
        )
};
