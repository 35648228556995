import backgroudImage from "assets/img/loadingbg.png";

const styles = {
  backgroundImage: {
    backgroundImage: `url(${backgroudImage})`,
    backgroundSize: "cover",
    height: "100%"
  },
  container: {
    minHeight: "180px",
    flexDirection: "row",
    alignItems: "center",
    display: "inline-flex",
    justifyContent: "center",
    width: "100%"
  }
};

export default styles;
